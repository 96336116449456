import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
// import QRcode from '../images/lineQRcode.jpg'
// // AD命名會被瀏覽器阻廣告套件屏蔽
// import popular_03 from '../images/delivery_20211208.webp';
// import popular_02 from '../images/AD_20210008.webp';
// import popular_01 from '../images/AD_20210707.jpeg';
import menu_1 from '../images/menu_1_20231031.webp';
import menu_2 from '../images/menu_2_20231031.webp';
// import menu_4 from '../images/menu_20220406_03.webp';
// import ad_01 from '../images/ad01.jpeg';
import specialmenu_1 from '../images/sp_menu_1_20230419.webp';
import specialmenu_2 from '../images/sp_menu_2_20230419.webp';

class DeliveryPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid padding0" id="page-delivery">
        <Helmet>
          <title>鄧師傅功夫菜-外送菜單</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />

        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">外送菜單</p>
          <div className="col-auto DirectionTitle">首頁 / 外送菜單</div>
        </div>
        <div className="popular-text">
          <div className="delivery">
            【鄧師傅門市外送 GO】<br />
            <br />
            結合PANDAGO外送服務，讓您在家就可以享受美食！<br />
            ❗ 營業時間：11:00~13:00、17:00~20:00<br />
            ❗ 外送須提前至少30分鐘預定<br />
            ❗ 付款方式：線上刷卡<br />
            ❗ 運費規則說明<br />
            ✸ 3公里內(含3公里) 運費$105元 【免運門檻】$1,200元<br />
            ✸ 5公里內(含5公里) 運費$135元 【免運門檻】$1,500元<br />
            ❗ 目前僅有中正店、青年店、美術館店配合，點選右側連結線上點餐<br />
            <br />
          </div>
          {/* <a href="http://line.me/ti/p/@chefteng?fbclid=IwAR11Kw5vJ4t6TMsioSLlZkLSHqKYcjeBfMxK5KQNg77vyzluIZ4AW34dUxk" className="link"><img src={QRcode} alt="鄧師傅LINE"/></a> */}
          <div className="storesTel">
            <table>
              {/*}
              <tr>
                <td><a title="外送訂購" href="https://shop.ichefpos.com/store/NMFsHu6e/ordering">🛵 高雄中正店</a></td>
                <td><a href="tel:07-2361822">📞 07-2361822</a></td>
              </tr>
              <tr>
                <td><a title="外送訂購" href="https://shop.ichefpos.com/store/vlX8Ybpd/ordering">🛵 高雄青年店</a></td>
                <td><a href="tel:07-2515622">📞 07-2515622</a></td>
              </tr>
              <tr>
                <td><a title="外送訂購" href="https://shop.ichefpos.com/store/0DedRpUC/ordering">🛵 高雄美術館店</a></td>
                <td><a href="tel:07-5559722">📞 07-5559722</a></td>
              </tr>
              {*/}
              <tr>
                <td><a> 高雄中正店</a></td>
                <td><a href="tel:07-2361822">📞 07-2361822</a></td>
              </tr>
              <tr>
                <td><a> 高雄青年店</a></td>
                <td><a href="tel:07-2515622">📞 07-2515622</a></td>
              </tr>
              <tr>
                <td><a> 高雄美術館店</a></td>
                <td><a href="tel:07-5559722">📞 07-5559722</a></td>
              </tr>
              <tr>
                <td><a> 高雄漢神成功店</a></td>
                <td><a href="tel:07-2161222">📞 07-2161222</a></td>
              </tr>
              <tr>
                <td><a> 高雄漢神巨蛋店</a></td>
                <td><a href="tel:07-5227322">📞 07-5227322</a></td>
              </tr>
              <tr>
                <td><a> 高雄夢時代店</a></td>
                <td><a href="tel:07-8227822">📞 07-8227822</a></td>
              </tr>
              <tr>
                <td><a> 高雄大樂店</a></td>
                <td><a href="tel:07-3924698">📞 07-3924698</a></td>
              </tr>
              <tr>
                <td><a> 高雄義享店</a></td>
                <td><a href="tel:07-5568269">📞 07-5568269</a></td>
              </tr>
            </table>
            {/*}
            <a href="tel:07-2361822"><span>高雄中正店</span><i>📞</i>07-2361822</a>
            <a href="tel:07-2515622"><span>高雄青年店</span><i>📞</i>07-2515622</a>
            <a href="tel:07-5559722"><span>高雄美術館店</span><i>📞</i>07-5559722</a>
            <a href="tel:07-2161222"><span>高雄漢神成功店</span><i>📞</i>07-2161222</a>
            <a href="tel:07-5227322"><span>高雄漢神巨蛋店</span><i>📞</i>07-5227322</a>
            <a href="tel:07-8227822"><span>高雄夢時代店</span><i>📞</i>07-8227822</a>
            <a href="tel:07-3924698"><span>高雄大樂店</span><i>📞</i>07-3924698</a>
            <a href="tel:07-5568269"><span>高雄義享店</span><i>📞</i>07-5568269</a>
            {*/}
          </div>
        </div>
        <div className="img">
          {/*}
          <img src={specialmenu_1} alt="鄧師傅母親節團圓宴-外帶套餐" /> 
          <img src={specialmenu_2} alt="鄧師傅母親節團圓宴-內用豪華饗宴" /> 
          {*/}
          <img src={menu_1} alt="鄧師傅高雄菜單" />
          <img src={menu_2} alt="鄧師傅高雄菜單" />
        </div>

        <footer.container />
      </div>
    )
  }
}

export default DeliveryPageComponent;
